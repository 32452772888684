
export const softSkills = [
    {
        name: 'Problem-solving',
        description: 'Always strive to find the optimal solution.'
    },
    {
        name: 'Attention to details',
        description:
        'Priority number one at all times'
    },
    {
        name: 'Self-motivation',
        description:
            'Driven by a genuine passion for growth. Self-development plays crucial aspect in my life'
    },

    {
        name: 'Flexibility',
        description:
            'It helps me adapt to changes, switch gears when necessary, and approach challenges with an open mind and readiness to adjust plans as needed'
    },
    {
        name: 'Time Management and Organization',
        description:
            'Being good at managing time and staying organized is a game changer. It helps me stay focused, tackle tasks in order of priority, and meet deadlines without any hassle'
    },
    {
        name: ' Adaptability to Technology',
        description:
            "I'm adept at quickly grasping new technology. Learning and utilizing new tools comes naturally to me"
    },
    {
        name: ' Collaboration and Teamwork',
        description:
            'Collaboration and teamwork are crucial for me because they help me connect with others, leverage our strengths, and reach shared goals'
    }
];






  

  




