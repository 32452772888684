import React, { useEffect, useState } from "react";
import "./SideMenu.css";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-scroll";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AllWorks from "../Works/AllWorks";

const SideMenu = ({ sideMenu, setSideMenu, setHamburgerMenuBlue }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const isHomePage = location.pathname == "/";

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 980); // Adjust threshold as needed
    };

    handleResize(); // Check screen size initially
    window.addEventListener("resize", handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", handleResize); // Remove event listener on component unmount
    };
  }, [window.innerWidth]);

  const uniqueKey = Math.floor();

  const closingSideMenuHandler = () => {
    setSideMenu(false);
    setHamburgerMenuBlue(false); // Reset hamburgerMenuBlue to false
  };
  const handleClick = () => {
    navigate('/'); // This navigates back to the previous page in history
  };

  return (
    <>
      <AnimatePresence mode="wait">
        {sideMenu && (
          <>
            <div
              className="sideMenu-overlay"
              onClick={() => closingSideMenuHandler()}
            ></div>
            <motion.div
              key={uniqueKey}
              initial={{ x: "100vw" }}
              animate={{ x: 0 }} // Adjust width based on screen size
              exit={{ x: "100vw" }}
              transition={{ delay: 0.1, duration: 0.4, ease: "easeOut" }}
              className="sideMenu"
              style={{ width: isSmallScreen ? "100%" : "45%" }} // Adjust width based on screen size
            >
              <div className="sideMenu-topSection">
                <p>NAVIGATION</p>
              </div>
              <div className="sideMenu-midSection">
                <nav>
                  <ul>
                    <Link className="home-active" onClick={handleClick}>
                      <motion.li
                        initial={{ x: 460, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                          delay: 0.4,
                          duration: 0.2,
                          type: "spring",
                          stiffness: 120,
                        }}
                        style={{ listStyle: "none", TextDecoder: "none" }}
                        onClick={closingSideMenuHandler}
                      >
                        <a href="">Home</a>
                      </motion.li>
                    </Link>

                    <NavLink className="about-active" to="/about">
                      <motion.li
                        initial={{ x: 460, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                          delay: 0.5,
                          duration: 0.2,
                          type: "spring",
                          stiffness: 120,
                        }}
                        onClick={closingSideMenuHandler}
                      >
                        <a href="">About</a>
                      </motion.li>
                    </NavLink>
                    {isHomePage ? (
                      <>
                        <Link
                          to="projects-section"
                          activeClass="projects-active"
                          spy={true}
                          smooth={true}
                          offset={50} // Adjust the offset based on your layout
                          duration={700}
                        >
                          <motion.li
                            initial={{ x: 460, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                              delay: 0.6,
                              duration: 0.2,
                              type: "spring",
                              stiffness: 120,
                            }}
                            onClick={closingSideMenuHandler}
                          >
                            <a href="">Projects</a>
                          </motion.li>
                        </Link>
                      </>
                    ) : (
                      <>
                        <NavLink
                          to="/all-projects"
                          activeClass="projects-active"
                          spy={true}
                          smooth={true}
                          offset={50} // Adjust the offset based on your layout
                          duration={700}
                        >
                          <motion.li
                            initial={{ x: 460, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                              delay: 0.6,
                              duration: 0.2,
                              type: "spring",
                              stiffness: 120,
                            }}
                            onClick={closingSideMenuHandler}
                          >
                            <a href="">Projects</a>
                          </motion.li>
                        </NavLink>
                      </>
                    )}

                    <NavLink
                      className="contact-active"
                      activeClass="contact-active"
                      spy={true}
                      smooth={true}
                      duration={700}
                      to="/contact"
                    >
                      <motion.li
                        initial={{ x: 460, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                          delay: 0.7,
                          duration: 0.2,
                          type: "spring",
                          stiffness: 120,
                        }}
                        onClick={closingSideMenuHandler}
                      >
                        <a href="">Contact</a>
                      </motion.li>
                    </NavLink>
                  </ul>
                </nav>
              </div>
              <div className="sideMenu-bottomSection">
                <p>SOCIALS</p>
                <ul>
                  <li>
                    <a
                      href="https://www.instagram.com/armandeo_/"
                      target="_blank"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/armands-ta%C5%A1k%C4%81ns-25b74b273/"
                      target="_blank"
                    >
                      LinkdeIn
                    </a>
                  </li>
                  <li>
                    <a href="https://github.com/TArmandss" target="_blank">
                      Github
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/ATaskans30088" target="_blank">
                      X
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/armands.tashkans"
                      target="_blank"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default SideMenu;
