import { SiWebpack } from 'react-icons/si';
import { SiMysql } from 'react-icons/si';
import { FaGithub, FaBitbucket, FaBootstrap } from 'react-icons/fa';
import { DiJavascript1 } from 'react-icons/di';
import { FaNodeJs, FaJava } from 'react-icons/fa';
import { IoLogoPython } from 'react-icons/io';
import { BiLogoTypescript } from 'react-icons/bi';
import { FaFigma } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';
import { SiAdobephotoshop } from 'react-icons/si';
import { SiVite } from 'react-icons/si';
import { SiRedux } from 'react-icons/si';
import { SiTailwindcss } from 'react-icons/si';
import { SiXampp } from 'react-icons/si';
import { DiVisualstudio } from 'react-icons/di';
import { SiPycharm } from 'react-icons/si';
import { SiFlask } from 'react-icons/si';
import { SiJira } from 'react-icons/si';
import { SiMqtt } from 'react-icons/si';
import { TbSql } from 'react-icons/tb';
import { SiMongodb } from 'react-icons/si';
export const icons = {
    'FRONT-END': [
        { icon: <SiWebpack />, description: 'Webpack', status: 'relevant' },
        { icon: <FaReact />, description: 'React', status: 'relevant' },
        {
            icon: <FaBootstrap />,
            description: 'Bootstrap',
            status: 'notrelevant'
        },
        {
            icon: <SiAdobephotoshop />,
            description: 'Adobe Ps',
            status: 'notrelevant'
        },
        { icon: <SiVite />, description: 'Vite', status: 'relevant' },
        { icon: <SiRedux />, description: 'Redux', status: 'relevant' },
        { icon: <SiTailwindcss />, description: 'Tailwind', status: 'relevant' }
    ],
    'DATABASE': [{ icon: <SiMysql />, description: 'MySQL', status: 'relevant' },
    { icon: <SiMongodb />, description: 'MongoDB', status: 'relevant' }

    ],
    'VERSION CONTROLS': [
        { icon: <FaGithub />, description: 'GitHub', status: 'relevant' },
        { icon: <FaBitbucket />, description: 'Bitbucket', status: 'norelevant' }
    ],
    'LANGUAGES': [
        {
            icon: <DiJavascript1 />,
            description: 'JavaScript',
            status: 'relevant'
        },
        { icon: <IoLogoPython />, description: 'Python', status: 'relevant' },
        { icon: <TbSql />, description: 'SQL', status: 'relevant' },
        { icon: <BiLogoTypescript />, description: 'TypeScript', status: 'relevant' }
    ],
    'OTHERS': [
        { icon: <SiXampp />, description: 'XAMPP', status: 'relevant' },
        { icon: <FaNodeJs />, description: 'Node.js' },
        { icon: <DiVisualstudio />, description: 'VS', status: 'relevant' },
        { icon: <SiPycharm />, description: 'PyCharm', status: 'relevant' },
        { icon: <SiFlask />, description: 'Flask', status: 'norelevant' },
        { icon: <SiJira />, description: 'Jira', status: 'norelevant' },
        { icon: <SiMqtt />, description: 'MQTT', status: 'norelevant' },
        { icon: <FaFigma />, description: 'Figma', status: 'relevant' }
    ]
 
};
