import React, { useEffect, useState, useRef } from "react";
import "./Works.css";
import "../HomePage/HomeLayOut.css";
import { MdCopyright } from "react-icons/md";
import { CiVideoOn } from "react-icons/ci";
import Footer from "../Footer/Footer";
import HamburgerMenu from "../SideMenu/HamburgerMenu";
import SideMenuSection from "../SideMenu/SideMenu";
import { useParams, useNavigate } from "react-router";
import projectList from "../../projectList";
import { NavLink } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaPlay, FaPauseCircle } from "react-icons/fa";
import { AnimatePresence, motion, useScroll } from "framer-motion";
import Fade from "react-reveal/Fade";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";

function Works({
  slideInMenu,
  showMenuIcon,
  setShowMenuIcon,
  sideMenu,
  setSideMenu,
  setHamburgerMenuBlue,
  hamburgerMenuBlue,
}) {
  const { title } = useParams();
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isvideoPaused, setIsVideoPaused] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [title]);

  useEffect(() => {
    const project = projectList.find((project) => project.title === title);
    if (project) {
      const projects = projectList.filter((p) => p.tech === project.tech);
      setFilteredProjects(projects);
    }
  }, []);

  const currentProject = filteredProjects.find(
    (project) => project.title === title
  );

  const nextCaseHandler = () => {
    if (!isLastProject) {
      const currentIndex = filteredProjects.findIndex(
        (project) => project.title === currentProject.title
      );
      const nextIndex =
        currentIndex === filteredProjects.length - 1 ? 0 : currentIndex + 1;
      navigate(`/project/${filteredProjects[nextIndex].title}`);
    } else {
      navigate(`/project/${projectList[0].title}`);
      setFilteredProjects([...projectList]);
    }
  };

  const handleVideoClick = () => {
    setIsPlaying(!isPlaying);
  };

  const toggleVideoPlayback = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsVideoPaused(false);
      } else {
        videoRef.current.pause();
        setIsVideoPaused(true);
      }
    }
  };

  useEffect(() => {
    if (isPlaying) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    if (videoRef.current) {
      isPlaying ? videoRef.current.play() : videoRef.current.pause();
    }
  }, [isPlaying]);

  const currentIndex = filteredProjects.findIndex(
    (project) => project.title === currentProject.title
  );
  const isLastProject = currentIndex === filteredProjects.length - 1;

  const loadingVideoHandler = () => {
    setIsPlaying(false);
    setIsVideoLoaded(false);
  };
  return (
    <div className="works-wrapper">
      <Toaster position="top-right" toastOptions={{ duration: 2000 }} />

      <SideMenuSection
        setHamburgerMenuBlue={setHamburgerMenuBlue}
        sideMenu={sideMenu}
        setSideMenu={setSideMenu}
      />
      <HamburgerMenu
        sideMenu={sideMenu}
        setSideMenu={setSideMenu}
        showMenuIcon={showMenuIcon}
        setShowMenuIcon={setShowMenuIcon}
        setHamburgerMenuBlue={setHamburgerMenuBlue}
        hamburgerMenuBlue={hamburgerMenuBlue}
      />
      {isPlaying && (
        <div className="video-container">
          <button onClick={loadingVideoHandler}>
            <IoClose className="close-video-btn" />
          </button>
          <div className="video-box">
            <p className="project-name">{title}</p>
            {!isVideoLoaded && <div className="loader"></div>}
            <video
              ref={videoRef}
              src={currentProject.video}
              loop
              style={{ display: `${isVideoLoaded ? "block" : "none"}` }}
              muted
              playsInline
              onClick={toggleVideoPlayback}
              onLoadedData={() => setIsVideoLoaded(true)}
            />
          </div>
        </div>
      )}
      <Fade top distance="10%" duration={800}>
        <div className="works-section-page">
          <div className="works">
            <NavLink
              to="/"
              style={{ textDecoration: "none" }}
              className="copyright_txt"
            >
              <MdCopyright /> Code by Armands
            </NavLink>
            {currentProject && (
              <>
                <div className="work_name">
                  <h1>{currentProject.title} </h1>
                  <div className="decor">
                    <div className="line"></div>
                    <div
                      className="video_buble"
                      onClick={() => {
                        if (currentProject.Restriction) {
                          toast.error("Video is not available yet");
                        } else {
                          handleVideoClick();
                        }
                      }}
                    >
                      <CiVideoOn className="rotate" />
                    </div>
                  </div>
                </div>
                <div className="work_description">
                  <div className="first-row">
                    <div className="description">
                      {currentProject.description}
                    </div>
                    <div className="addition">
                      {currentProject["side-note"]}
                      <br />
                      {currentProject.link && (
                        <>
                          Link : <br />
                          <a
                            href={`${currentProject.link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {currentProject.link}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="second-row">
                    <div className="tools-section">
                      {currentProject.tools?.map((tool, index) => (
                        <div key={index} className="tool">
                          {tool}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="image-section">
                  <img
                    className="work_image"
                    src={currentProject.img}
                    alt={currentProject.title}
                    loading="lazy"
                  />
                    {currentProject?.img_second && (
                  <img
                    className="work_image"
                    src={currentProject.img_second}
                    loading="lazy"
                  />
                )}
                </div>

              
                {currentProject.title !== "Online Shopping" ? (
                  <>
                    {currentProject.Restriction === "Restricted" ? (
                      <>
                        <div
                          className="work-video-restricted"
                          style={{
                            backgroundImage: `url(${currentProject.img})`,
                          }}
                        >
                          <p>Unfortunately, this video cannot be shown.</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="work-video"
                          style={{
                            backgroundImage: `url(${currentProject.img})`,
                          }}
                          onClick={handleVideoClick}
                        >
                          <button>
                            <FaPlay className="play-btn" />
                          </button>
                        </div>
                      </>
                    )}
                  </>
               ) : (null)}
              </>
            )}

            {currentProject && (
              <div className="next-project-section" onClick={nextCaseHandler}>
                <button>
                  {isLastProject && filteredProjects.length !== 7
                    ? "Next Section (All)"
                    : "Next Case"}
                  <IoIosArrowRoundForward className="next_case_icon" />
                </button>
                <img
                  className="next-work"
                  src={
                    isLastProject
                      ? projectList[0].img
                      : filteredProjects[
                          (currentIndex + 1) % filteredProjects.length
                        ].img
                  }
                  alt="Next Project"
                  loading="lazy"
                />
              </div>
            )}
          </div>
        </div>
      </Fade>
      <Footer />
    </div>
  );
}

export default Works;
