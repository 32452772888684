import React, { useEffect, useState, useRef } from "react";
import "./HomeLayOut.css";
import { AnimatePresence, motion, useScroll } from "framer-motion";
import { Link, NavLink } from "react-router-dom";
import TechSection from "./TechSection.jsx";
import { CiMenuFries } from "react-icons/ci";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineGithub } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { CiVideoOn } from "react-icons/ci";
import { BsCodeSlash } from "react-icons/bs";
import { PiArrowBendDoubleUpRightBold } from "react-icons/pi";
import { LuLayoutGrid } from "react-icons/lu";
import { BsArrowUpRight } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { MdCopyright } from "react-icons/md";
// import videBg from '../../assets/video/AdobeStock_486685290.mp4';
import { GoArrowDownLeft } from "react-icons/go";
import SideMenuSection from "../../components/SideMenu/SideMenu";
import HamburgerMenu from "../../components/SideMenu/HamburgerMenu";
import { softSkills } from "../../softSkills";
import projectList from "../../projectList.js";

import EmailForum from "../EmailForum/EmailForum";
import Marquee from "react-fast-marquee";
import Footer from "../Footer/Footer.jsx";
import Aos from "aos";
import "aos/dist/aos.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import Fade from "react-reveal/Fade";

import { GrFormNextLink } from "react-icons/gr";
import { MdArrowOutward } from "react-icons/md";
import LazyLoadBackground from "./LazyLoadBackground.jsx"; // Adjust the path if necessary
import { useInView } from "react-intersection-observer";

function HomeLayOut({
  slideInMenu,
  showMenuIcon,
  setShowMenuIcon,
  sideMenu,
  setSideMenu,
}) {
  const [activeFlexSkills, setActiveFlexSkills] = useState(false);
  const [activeFlexTech, setActiveFlexTech] = useState(false);
  const [skillsIndex, setSkillsIndex] = useState(0);
  const [enableEmailForum, setEnableEmailForum] = useState(false);
  const [navigationBarSelecter, setNavigationBarSelecter] = useState(true);
  const [activeLayoutGrid, setActiveLayoutGrid] = useState(true);
  const [activeLayoutMenu, setActiveLayoutMenu] = useState(false);
  const [isLoadedProjects, setIsLoadedProjects] = useState(false);

  const { ref: aboutmeRef, inView: isVisibleAboutMe } = useInView({
    triggerOnce: true,
    threshold: 0.01,
  });
  const { ref: pickProjectRef, inView: isPickProjectVisible } = useInView({
    triggerOnce: true,
    threshold: 0.01,
  });

  const [hamburgerMenuBlue, setHamburgerMenuBlue] = useState(false);

  const activeLayoutGirdHandler = () => {
    setActiveLayoutGrid(true);
    setActiveLayoutMenu(false);
  };
  const activeLayoutMenuHandler = () => {
    setActiveLayoutGrid(false);
    setActiveLayoutMenu(true);
  };

  function toggleFlexSkills() {
    setActiveFlexSkills(!activeFlexSkills);
    setActiveFlexTech(false); // Set the other state to false
  }
  function toggleFlexTech() {
    setActiveFlexTech(!activeFlexTech);
    setActiveFlexSkills(false); // Set the other state to false
  }

  const softSkillsLength = softSkills.length - 1;

  const nextSoftSkillHandler = () => {
    if (skillsIndex >= softSkillsLength) {
      setSkillsIndex(0);
    } else {
      setSkillsIndex((prevState) => prevState + 1);
    }
  };

  const prevSoftSkillHandler = () => {
    if (skillsIndex == 0) {
      setSkillsIndex(softSkillsLength);
    } else {
      setSkillsIndex((prevState) => prevState - 1);
    }
  };

  useEffect(() => {
    if (enableEmailForum) {
      document.body.classList.add("no-scroll"); // Add class to disable scrolling
      setNavigationBarSelecter(false);
    } else {
      document.body.classList.remove("no-scroll"); // Remove class to enable scrolling
    }
  }, [enableEmailForum]);

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", " 1.33 1"],
  });

  const SoftSkillsSection = useRef();
  const softSkillsAnimation = {
    initial: {
      x: -200,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
    },
  };

  const [sliderCurrentIndex, seSliderCurrentIndex] = useState(0);
  const optionsButtons = ["All", "JavaScript", "ReactJs", "Full-stack"];
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [activeButton, setActiveButton] = useState("All");

  const handleButtonClick = ({ optionBtn, index }) => {
    setActiveButtonIndex(index);
    setActiveButton(optionBtn);
    seSliderCurrentIndex(0);
  };
  console.log(window.innerHeight);

  const ProjectsByTopic = {
    All: projectList,
    JavaScript: projectList.filter((project) => project.tech === "JavaScript"),
    ReactJs: projectList.filter((project) => project.tech === "ReactJs"),
    "Full-stack": projectList.filter(
      (project) => project.tech === "Full-stack"
    ),
  };

  const displayedProjects = ProjectsByTopic[activeButton] || [];

  const goToSlide = (index) => {
    if (index < 0) {
      seSliderCurrentIndex(displayedProjects.length - 1);
    } else if (index >= displayedProjects.length) {
      seSliderCurrentIndex(0);
    } else {
      seSliderCurrentIndex(index);
    }
  };

  const nextSlideItem = () => {
    goToSlide(sliderCurrentIndex + 1);
  };

  const prevSlideItem = () => {
    goToSlide(sliderCurrentIndex - 1);
  };
  const [changeContent, setChangeContent] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setChangeContent(window.innerWidth <= 845); // Adjust threshold as needed
    };

    handleResize(); // Check screen size initially
    window.addEventListener("resize", handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", handleResize); // Remove event listener on component unmount
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <div className="wrapper-main">
        {enableEmailForum && (
          <EmailForum
            setEnableEmailForum={setEnableEmailForum}
            enableEmailForum={enableEmailForum}
            setNavigationBarSelecter={setNavigationBarSelecter}
          />
        )}

        {/* <div className="grid-layout"> */}
        <SideMenuSection
          setHamburgerMenuBlue={setHamburgerMenuBlue}
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
        />
        <HamburgerMenu
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
          showMenuIcon={showMenuIcon}
          setShowMenuIcon={setShowMenuIcon}
          setHamburgerMenuBlue={setHamburgerMenuBlue}
          hamburgerMenuBlue={hamburgerMenuBlue}
        />
        <LazyLoadBackground
          className="top-section"
          backgroundImage="/assets/img/white.png"
        >
          <div className="top-section-corner">
            <p>Portfolio</p>
          </div>
          <div className="top-section_nav">
            <NavLink
              to="/contact"
              className="contact-link"
              style={{ textDecoration: "none" }}
            >
              Contact me <MdArrowOutward />
            </NavLink>
          </div>
          <NavLink
            to={"/"}
            className="copyright_txt"
            style={{ textDecoration: "none" }}
          >
            <MdCopyright /> Code by Armands
          </NavLink>

          {changeContent ? (
            <div className="marquee-div">
              <Marquee behavior="scroll" direction="left" scrollamount="40">
                <h1> Passionate Web developer</h1>
              </Marquee>
              <h2>
                Driven by a passion for creating interactive, visually appealing
                websites with exceptional design and functionality
              </h2>
            </div>
          ) : (
            <>
              <div className="top-left-content">
                <h1>
                  {" "}
                  Passionate Web <br /> developer
                </h1>
                <div className="bottom-section-of-hero-section">
                  <span className="line-icons"></span>
                  <a
                    href="https://www.instagram.com/armandeo_/"
                    target="_blank"
                  >
                    <AiFillInstagram className="icon" />
                  </a>
                  <a href="https://twitter.com/ATaskans30088" target="_blank">
                    {" "}
                    <AiOutlineTwitter className="icon" />
                  </a>
                  <a
                    href="https://www.facebook.com/armands.tashkans"
                    target="_blank"
                  >
                    <BsFacebook className="icon" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/armands-ta%C5%A1k%C4%81ns-25b74b273/"
                    target="_blank"
                  >
                    <BsLinkedin className="icon" />
                  </a>
                  {/* <a href="https://github.com/TArmandss" target="_blank">
                    <AiOutlineGithub className="icon" />
                  </a> */}

                  <Link to="/about" style={{ textDecoration: "none" }}>
                    <button className="about-me">
                      About me
                      <IoIosArrowRoundForward className="about-me_arrow" />
                    </button>
                  </Link>
                </div>
              </div>
              <h2>
                Driven by a passion for creating interactive, visually appealing
                websites with exceptional design and functionality
              </h2>
            </>
          )}

          <GoArrowDownLeft className="arrow-down-icon" />
        </LazyLoadBackground>
        <Fade top distance="10%" duration={800}>
          <div className="about_author_section">
            <div className="about-author_content">
              <div className="grid-dummy-div">
                <IoIosArrowRoundForward className="about-author_arrow" />
                {/* <h1 className="why_me_txt">...WHY ME ?</h1> */}
              </div>
              <div className="get-to-know-me">
                <div className="border-top-none">
                  <p className="item-title">PREVIOUS EXPERIENCE</p>
                  <p className="item-description">
                    Rēzeknes Tehnoloģiju akadēmija | FULL STACK WEB DEVELOPER
                  </p>
                </div>
                <div className="item">
                  <p className="item-title">STUDIES</p>
                  <a
                    href="https://www.rta.lv/"
                    target="_blank"
                    className="item-description"
                  >
                    Rēzeknes Tehnoloģiju akadēmija
                  </a>
                </div>
                <div className="item">
                  <p className="item-title">DEGREE</p>
                  <p className="item-description">
                    Associate degree | bachelor's degree ...
                    <br />
                  </p>
                </div>
                <div className="item">
                  <p className="item-title">LANGUAGES</p>
                  <p className="item-description">
                    English | Latvian | Russian
                  </p>
                </div>
                <div className="item">
                  <p className="item-title">LOCATION</p>
                  <p className="item-description">Latvia | Rēzekne</p>
                </div>
              </div>
            </div>
          </div>
        </Fade>

        <Fade top distance="10%" duration={800}>
          <div className="skills-section">
            <motion.div
              className={`soft-skills ${activeFlexSkills ? "active" : ""}`}
              onClick={toggleFlexSkills}
              viewport={{ once: true }}
              ref={SoftSkillsSection}
            >
              {activeFlexSkills ? (
                <div className="soft-skills-grid">
                  <div className="soft-skill-item">
                    <div className="soft-skills-inner-grid">
                      <div className="soft-skills_text_content">
                        <motion.h1
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="description-soft-skills"
                        >
                          {softSkills[skillsIndex].name}
                        </motion.h1>
                        <p>{softSkills[skillsIndex].description}</p>
                      </div>
                    </div>
                    <div className="soft-skills-btns">
                      <div className="left-btn">
                        <IoIosArrowBack
                          className="softSkills_btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            prevSoftSkillHandler();
                          }}
                        />
                      </div>
                      <div className="right-btn">
                        <IoIosArrowForward
                          className="softSkills_btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            nextSoftSkillHandler();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="soft-skills_intro">
                  <BsArrowUpRight className="icon-arrow-askew" />
                  <motion.div
                    initial={{ y: -100, opacity: 0 }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      type: "spring",
                      stiffness: 2000,
                    }}
                    transition={{ duration: 0.6 }} // Set the duration for the animation
                    className={`soft-skills-title ${
                      window.innerWidth <= 1096 ? "moved-up" : ""
                    }`}
                  >
                    <span className="positive-span">
                      <h1> Soft skills</h1>
                      <div className="span-line-positive"></div>
                    </span>
                    <span className="negative-span">
                      <h1> Soft skills</h1>
                      <div className="span-line-negative"></div>
                    </span>
                  </motion.div>
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      type: "spring",
                      stiffness: 2000,
                    }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                    className={`hover-text ${activeFlexTech && "hide"}`}
                  >
                    PERSONA
                  </motion.p>
                </div>
              )}
            </motion.div>

            <div
              className={`technologies ${activeFlexTech ? "active" : "shrink"}`}
              onClick={toggleFlexTech}
            >
              {activeFlexTech ? (
                <>
                  <TechSection />
                </>
              ) : (
                <>
                  <BsArrowUpRight className="icon" />
                  <motion.h1
                    initial={{ y: -100, opacity: 0 }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      type: "spring",
                      stiffness: 2000,
                    }}
                    transition={{ duration: 0.6 }} // Set the duration for the animation
                  >
                    <BsCodeSlash className="icon-html" /> technologies
                  </motion.h1>

                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      type: "spring",
                      stiffness: 2000,
                    }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                    className="brief_description_of_tech_section"
                  >
                    Introducing the tools I use or have a strong background in
                  </motion.p>

                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, type: "spring", stiffness: 2000 }}
                    transition={{ duration: 0.6, delay: 1.0 }}
                  >
                    <span>LIST OF TOOLS</span>
                    <span>LIST OF TOOLS</span>
                  </motion.p>
                </>
              )}
            </div>
          </div>
        </Fade>

          <div className="projects">
            <div className="pick-project-section projects-section">
              <div>
                <h1 className="project-title">Works</h1>
              </div>
              <div>
                {optionsButtons.map((optionBtn, index) => (
                  <button
                    className={
                      index === activeButtonIndex ? "btn-active" : "btn"
                    }
                    key={index}
                    onClick={() => handleButtonClick({ optionBtn, index })}
                  >
                    {optionBtn}
                    {activeLayoutGrid && optionBtn === "All" && (
                      <span
                        className="project-list-length"
                        style={{
                          color: `${
                            activeButtonIndex === 0 ? "#0466c8" : "black"
                          }`,
                        }}
                      >
                        {projectList.length}
                      </span>
                    )}
                  </button>
                ))}
              </div>

              <div className="layout-works">
                <CiMenuFries
                  className={`layout-icon layout-icon-${
                    !activeLayoutGrid && "active"
                  }`}
                  onClick={() => {
                    activeLayoutMenuHandler();
                  }}
                />
                <LuLayoutGrid
                  className={`layout-icon layout-icon-${
                    !activeLayoutMenu && "active"
                  }`}
                  onClick={() => {
                    activeLayoutGirdHandler();
                  }}
                />
              </div>
            </div>

            {changeContent ? (
              <div className="show-cases-small-screens">
                {projectList &&
                  displayedProjects.map((project, index) => (
                    <div className="projects-small-screens">
                      {project.title === "Online Shopping" && (
                        <p
                          className="temporary-note"
                          style={{ color: "white" }}
                        >
                          In progress
                        </p>
                      )}
                      <Link to={`/project/${project.title}`}>
                        <img
                          className="img-small-screens"
                          src={project.img}
                          alt={project.title}
                          style={{ backgroundColor: `${project?.color}` }}
                          loading="lazy"
                        />
                      </Link>
                      <h1>{project.title}</h1>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{project.roles}</p>
                        <p>{project.status}</p>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <>
                {activeLayoutGrid ? (
                  <div className="show-cases">
                    {projectList &&
                      displayedProjects.map((project, index) => (
                        <div
                          initial={{ x: -100, opacity: 0 }}
                          animate={{
                            x: 0,
                            opacity: 1,
                            type: "spring",
                            stiffness: 2000,
                          }}
                          transition={{ duration: 0.6, delay: 0.6 }}
                          key={index}
                          onLoadedData={() => setIsLoadedProjects(true)}
                          className={`box-example ${
                            index === sliderCurrentIndex
                              ? "activeItem"
                              : "hiddenItem"
                          }`}
                        >
                          {project.title === "Online Shopping" && (
                            <p className="temporary-note">In progress</p>
                          )}
                          <div className="imgfalse"></div>
                          <div className="text">
                            <h1>{project.title}</h1>
                            <p className="project_description ">
                              {project.description}
                            </p>
                            <div className="tools">
                              {project.tools.map((icon, index) =>
                                index < 4 ? (
                                  <i className="tool_icons" key={index}>
                                    {icon}
                                  </i>
                                ) : index < 5 ? (
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "300",
                                    }}
                                  >
                                    {`+ ${project.tools.length - 4}`}
                                  </p>
                                ) : null
                              )}
                            </div>

                            <div className="button-section">
                              <Link
                                to={`/project/${project.title}`}
                                style={{
                                  textDecoration: "none",
                                  listStyle: "none",
                                }}
                              >
                                <button>
                                  <p>Show case</p>
                                  <IoIosArrowRoundForward className="showcase-btn_arrow" />
                                </button>
                              </Link>
                            </div>
                          </div>

                          <div className="img-div">
                            <img
                              src={project.img}
                              alt={project.title}
                              loading="lazy"
                            />
                          </div>
                        </div>
                      ))}
                    {activeLayoutGrid && displayedProjects.length > 1 && (
                      <>
                        <div
                          className="next-project-arrow"
                          onClick={nextSlideItem}
                        >
                          <IoIosArrowForward />
                        </div>
                        <div
                          className="prev-project-arrow"
                          onClick={prevSlideItem}
                        >
                          <IoIosArrowBack />
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="show-cases-menu-layout">
                    <ul>
                      <div>
                        <p>
                          NAME{" "}
                          <span className="project-list-length-grid-menu">
                            {displayedProjects.length}
                          </span>{" "}
                        </p>
                        <p>SERVICE</p>
                        <p>STATUS</p>
                      </div>
                      {displayedProjects.map((project, index) => (
                        <NavLink
                          to={`/project/${project.title}`}
                          style={{ textDecoration: "none", color: "#171717" }}
                        >
                          <li key={index}>
                            <h1 className="h1-one-hover">{project.title}</h1>
                            <p>{project.roles}</p>
                            <p>{project.status}</p>
                            <div
                              className="prjoect-vid-onhover"
                              style={{
                                backgroundImage: `url(${project.img})`, // Apply the grow animation
                              }}
                            ></div>
                          </li>
                        </NavLink>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>
      </div>
      <Footer
        activeLayoutGrid={activeLayoutGrid}
        setEnableEmailForum={setEnableEmailForum}
      />
    </div>
  );
}

export default HomeLayOut;
