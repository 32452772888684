import React, { useState } from "react";
import "./EmailForum.css";
import { BsArrowRight } from "react-icons/bs";
import { MdCopyright } from "react-icons/md";
import emailjs from "emailjs-com";
import { AiFillInstagram, AiOutlineTwitter, AiOutlineGithub } from "react-icons/ai";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

emailjs.init(process.env.REACT_APP_PUBLIC_KEY);

function EmailForum() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [textEmail, setTextEmail] = useState("");

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Armands",
      message: textEmail,
    };

    emailjs.send(serviceId, templateId, templateParams).then(
      (response) => {
        toast.success("The email has been sent successfully");
        setEmail("");
        setName("");
        setPhoneNumber("");
        setTextEmail("");
      },
      (error) => {
        toast.error("The email FAILED");
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
        <div className="forum-section">
          <div className="top-section-contact">
            <NavLink to="/" style={{ textDecoration: "none" }} className="navLink">
              <p className="copyright_txt">
                <MdCopyright /> Code by Armands
              </p>
            </NavLink>
            <div className="contacts">
              <h6>Contact me</h6>
              <div className="contact-info">
                <h2>E-MAIL</h2>
                <p>taskansarmands@gmail.com</p>
                <h2>LOCATION</h2>
                <p>Latvia , Rēzekne</p>
                <h2>SOCIALS</h2>
                <div className="icons-section">
                  <a href="https://www.instagram.com/armandeo_/" target="_blank">
                    <AiFillInstagram className="icon-contact-section" />
                  </a>
                  <a href="https://twitter.com/ATaskans30088" target="_blank">
                    <AiOutlineTwitter className="icon-contact-section" />
                  </a>
                  <a href="https://www.facebook.com/armands.tashkans" target="_blank">
                    <BsFacebook className="icon-contact-section" />
                  </a>
                  <a href="https://www.linkedin.com/in/armands-ta%C5%A1k%C4%81ns-25b74b273/" target="_blank">
                    <BsLinkedin className="icon-contact-section" />
                  </a>
                  <a href="https://github.com/TArmandss" target="_blank">
                    <AiOutlineGithub className="icon-contact-section" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="right-section-contact">
              <div className="name-input">
                <h1>What's your name?</h1>
                <input
                  type="text"
                  name="Name"
                  value={name}
                  id="input"
                  require
                  placeholder="Daniel..."
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="email-input">
                <h1>What's your email?</h1>
                <input
                  type="email"
                  name="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="input"
                  placeholder="daniel@gmail.com"
                  required
                />
              </div>
              <div className="phone-input">
                <h1>What's your phone number? (optional)</h1>
                <input
                  type="tel"
                  name="Phone"
                  value={phoneNumber}
                  id="input"
                  placeholder="+371 23333333"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="message-input">
                <h1>Your message</h1>
                <input
                  type="message"
                  name="Message"
                  className="input"
                  value={textEmail}
                  onChange={(e) => setTextEmail(e.target.value)}
                  placeholder="Hey Daniels, I'd like to ..."
                  required
                />
              </div>
              <button type="submit" className="submit-btn">
                Submit <BsArrowRight />
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EmailForum;
