import { useState } from "react";
import HomeLayOut from "../HomePage/HomeLayOut";
import IntroPage from "../Intro/IntroPage";

function MainHome({
  slideInMenu,
  showMenuIcon,
  setShowMenuIcon,
  sideMenu,
  setSideMenu,
  setIsLoading,
  isLoading
}) {
  // const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {!isLoading ? (
        <IntroPage setIsLoading={setIsLoading} key="intro"/>
      ) : (
        <HomeLayOut
          slideInMenu={slideInMenu}
          showMenuIcon={showMenuIcon}
          setShowMenuIcon={setShowMenuIcon}
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          key="home"
        />
      )}
    </>
  );
}

export default MainHome;
