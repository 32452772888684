import React from "react";
import videBg from "../../AdobeStock_430481998.mp4";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineGithub } from "react-icons/ai";
import { CiVideoOn } from "react-icons/ci";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";
import "./Footer.css";

function Footer() {
  return (
    <Fade top distance="10%" duration={1000}>
      <div className="footer-main">
        {/* <div className="video-container">
          <video autoPlay loop muted>
            <source src={videBg} type="video/mp4" />
          </video>
          <div className="video-overlay"></div>
        </div> */}

        <div className="bottom-content-absolute-content">
          <div className="footer-content">
            <div className="copyright-text">
              <h1>© 2024, Armands Taškāns portfolio</h1>
            </div>
            <div className="contact-icons">
              <div className="icons-bottom">
                <a href="https://www.instagram.com/armandeo_/" target="_blank">
                  <AiFillInstagram className="icon-contact" />
                </a>
                <a href="https://twitter.com/ATaskans30088" target="_blank">
                  {" "}
                  <AiOutlineTwitter className="icon-contact" />
                </a>
                <a
                  href="https://www.facebook.com/armands.tashkans"
                  target="_blank"
                >
                  <BsFacebook className="icon-contact" />
                </a>
                <a
                  href="https://www.linkedin.com/in/armands-ta%C5%A1k%C4%81ns-25b74b273/"
                  target="_blank"
                >
                  <BsLinkedin className="icon-contact" />
                </a>
                {/* <a href="https://github.com/TArmandss" target="_blank">
                  <AiOutlineGithub className="icon-contact" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
        {/* <NavLink className="contact-btn" to="/contact">
          GET IN TOUCH
        </NavLink> */}
      </div>
    </Fade>
  );
}

export default Footer;
