import React, { useState, useEffect } from 'react';
import './HamburgerMenu.css';

function HamburgerMenu({hamburgerMenuBlue,setHamburgerMenuBlue, sideMenu, setSideMenu, showMenuIcon, setShowMenuIcon }) {
  

  useEffect(() => {
    if (hamburgerMenuBlue) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling
    } else {
      document.body.style.overflow = 'auto'; // Allow scrolling
    }

    // Clean up
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [hamburgerMenuBlue]);

 
  const clickedMenuHandler = () => {
    setSideMenu(sideMenu => !sideMenu); // Toggle menu visibility
    setHamburgerMenuBlue(hamburgerMenuBlue => !hamburgerMenuBlue);
  };

  return (
    <div className={`popup-menu ${showMenuIcon ? 'visible' : ''}`}>
      <div className={`hamburger-menu ${hamburgerMenuBlue ? 'active' : ''}`} onClick={clickedMenuHandler}>
        <span className="hamburger-line line-top"></span>
        <span className="hamburger-line line-mid"></span>
        <span className="hamburger-line line-bottom"></span>
      </div>
    </div>
  );
}

export default HamburgerMenu;
