import React, { useState, useEffect } from "react";
import "./IntroPage.css";
import { motion } from "framer-motion";

function IntroPage({ setIsLoading }) {
  const [greetingIndex, setGreetingIndex] = useState(0);
  const greetingArray = [
    "Hello",
    "こんにちは",
    "Bonjour",
    "Hola",
    "Hallo",
    "Salve",
    "Здравствуйте",
    "Χαίρετε",
    "Olá",
    "مرحباً",
    "Sveiks",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setGreetingIndex((prevIndex) => {
        let newIndex = prevIndex + 1;
        if (newIndex === greetingArray.length) {
          clearInterval(interval); // Stop the interval
          setTimeout(() => {
            setIsLoading(true); // Call setIsLoading after a delay
          }, 500); // Adjust delay as needed
        }
        return newIndex % greetingArray.length;
      });
    }, 600); // Display each element for 600 milliseconds

    return () => {
      clearInterval(interval);
    };
  }, [greetingArray.length, setIsLoading]);

  return (
    <div className="intro">
      <motion.h1
        initial={{ y: 50, opacity: 0 }}
        animate={
          greetingIndex === 0
            ? { y: 0, opacity: 1 }
            : { y: 0, opacity: 1, transition: { delay: 0 } } // No initial delay for subsequent greetings
        }
      
        transition={{
          delay: 0.2,
          duration: 0.5,
          type: "spring",
          stiffness: 120,
        }}
        key={greetingIndex} // Ensure motion.h1 re-renders on greetingIndex change
      >
        {greetingArray[greetingIndex]}
      </motion.h1>
      <h2>Armands Taškāns | Portfolio 2024</h2>
    </div>
  );
}

export default IntroPage;
