import React, { useEffect, useState, useRef } from "react";
import "./Works.css";
import "../HomePage/HomeLayOut.css";
import { MdCopyright } from "react-icons/md";
import { CiVideoOn } from "react-icons/ci";
import Footer from "../Footer/Footer";
import HamburgerMenu from "../SideMenu/HamburgerMenu";
import SideMenuSection from "../SideMenu/SideMenu";
import { NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";
import { motion } from "framer-motion";
import projectList from "../../projectList";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";

function AllWorks({
  slideInMenu,
  showMenuIcon,
  setShowMenuIcon,
  sideMenu,
  setSideMenu,
  setHamburgerMenuBlue,
  hamburgerMenuBlue,
}) {
  const navigate = useNavigate();
  const [projectIndex, setPojectIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoRef = useRef(null);

  const handleClick = () => {
    setPojectIndex((index) => (index + 1) % projectList.length);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [projectIndex]);

  const handleVideoClick = () => {
    setIsPlaying(!isPlaying);
  };

  const toggleVideoPlayback = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  //   useEffect(()=>{
  //     navigate(`/all-projects/${projectList[projectIndex].title}`)
  //   })

  const loadingVideoHandler = () => {
    setIsPlaying(false);
    setIsVideoLoaded(false);
  };

  const nextProjectIndex = (projectIndex + 1) % projectList.length;

  return (
    <div>
      <div className="works-wrapper">
        <Toaster position="top-right" toastOptions={{ duration: 2000 }} />

        <SideMenuSection
          setHamburgerMenuBlue={setHamburgerMenuBlue}
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
        />
        <HamburgerMenu
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
          showMenuIcon={showMenuIcon}
          setShowMenuIcon={setShowMenuIcon}
          setHamburgerMenuBlue={setHamburgerMenuBlue}
          hamburgerMenuBlue={hamburgerMenuBlue}
        />
        {isPlaying && (
          <div className="video-container">
            <button onClick={loadingVideoHandler}>
              <IoClose className="close-video-btn" />
            </button>
            <div className="video-box">
              <p className="project-name">{projectList[projectIndex].title}</p>
              {!isVideoLoaded && <div className="loader"></div>}
              <video
                ref={videoRef}
                src={projectList[projectIndex].video}
                loop
                style={{ display: `${isVideoLoaded ? "block" : "none"}` }}
                muted
                playsInline
                onClick={toggleVideoPlayback}
                onLoadedData={() => setIsVideoLoaded(true)}
              />
            </div>
          </div>
        )}
        <div className="works-section-page">
          <div className="works">
            <NavLink
              to="/"
              style={{ textDecoration: "none" }}
              className="copyright_txt"
            >
              <MdCopyright /> Code by Armands
            </NavLink>
            <>
              <div className="work_name">
                <h1>{projectList[projectIndex].title} </h1>
                <div className="decor">
                  <div className="line"></div>
                  <div
                    className="video_buble"
                    onClick={() => {
                      if (projectList[projectIndex].Restriction) {
                        toast.error("Video is not available yet");
                      } else {
                        handleVideoClick();
                      }
                    }}
                  >
                    <CiVideoOn className="rotate" />
                  </div>
                </div>
              </div>
              <div className="work_description">
                <div className="first-row">
                  <div className="description">
                    {projectList[projectIndex].description}
                  </div>
                  <div className="addition">
                    {projectList[projectIndex]["side-note"]}
                    <br />
                    {projectList[projectIndex].link && (
                      <>
                        Link : <br />
                        <a
                          href={`${projectList[projectIndex].link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {projectList[projectIndex].link}
                        </a>
                      </>
                    )}
                  </div>
                </div>
                <div className="second-row">
                  <div className="tools-section">
                    {projectList[projectIndex].tools?.map((tool, index) => (
                      <div key={index} className="tool">
                        {tool}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <img
                className="work_image"
                src={projectList[projectIndex].img}
                alt={projectList[projectIndex].title}
                loading="lazy"
              />

              {projectList[projectIndex]?.img_second && (
                <img
                  className="work_image"
                  src={projectList[projectIndex].img_second}
                  loading="lazy"
                />
              )}

              {projectIndex > 0 && (
                <>
                  {projectList[projectIndex].Restriction === "Restricted" ? (
                    <div
                      className="work-video-restricted"
                      style={{
                        backgroundImage: `url(${projectList[projectIndex].img})`,
                      }}
                    >
                      <p>Unfortunately, this video cannot be shown.</p>
                    </div>
                  ) : (
                    <div
                      className="work-video"
                      style={{
                        backgroundImage: `url(${projectList[projectIndex].img})`,
                      }}
                      onClick={handleVideoClick}
                    >
                      <button>
                        <FaPlay className="play-btn" />
                      </button>
                    </div>
                  )}
                </>
              )}
            </>

            <div className="next-project-section" onClick={handleClick}>
              <button>
                Next Case
                <IoIosArrowRoundForward className="next_case_icon" />
              </button>
              <img
                className="next-work"
                src={projectList[nextProjectIndex].img}
                alt="Next Project"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default AllWorks;
