import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/HomePage/MainHome';
import AboutContent from './components/AboutContent/AboutContent';
import { AnimatePresence, motion } from 'framer-motion';
import Works from './components/Works/Works';
import ContactSection from '.././src/components/EmailForum/EmailForum';
import AllWorks from './components/Works/AllWorks';

const blackBox = {
    initial: {
        width: '100%',
        left: 0
    },
    animate: {
        width: 0,
        transition: {
            duration: 1.5,
            ease: [0.25, 0.1, 0.25, 1]
        }
    }
};
const blackBox1 = {
    initial: {
        width: '100%',
        left: 0
    },
    animate: {
        width: 0,
        transition: {
            duration: 1.9,
            ease: [0.87, 0, 0.13, 1]
        }
    }
};


const InitialTransition = ({ children }) => (
    <>
        <motion.div className="black-box" initial="initial" animate="animate" variants={blackBox} />
        <motion.div
            className="black-box1"
            initial="initial"
            animate="animate"
            variants={blackBox1}
        />

        {children}
    </>
);

function App() {
    const [sideMenu, setSideMenu] = useState(false);
    const [showMenuIcon, setShowMenuIcon] = useState(false);
    const [hamburgerMenuBlue, setHamburgerMenuBlue] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isAnimationDone , setIsAnimationDone] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 100; // Set a threshold value at which you want the menu to appear
            const isScrolledDown = window.scrollY > scrollThreshold;
            setShowMenuIcon(isScrolledDown);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const slideInMenu = event => {
        setSideMenu(sideMenu => !sideMenu);
    };

    return (
        <AnimatePresence mode="wait">
            <BrowserRouter forceRefresh={true}>
                <Routes>
                    <Route
                        index
                        element={
                            isLoading ? (
                                <InitialTransition>
                                    <Home
                                        slideInMenu={slideInMenu}
                                        showMenuIcon={showMenuIcon}
                                        setShowMenuIcon={setShowMenuIcon}
                                        sideMenu={sideMenu}
                                        setSideMenu={setSideMenu}
                                        setHamburgerMenuBlue={setHamburgerMenuBlue}
                                        hamburgerMenuBlue={hamburgerMenuBlue}
                                        setIsLoading={setIsLoading}
                                        isLoading={isLoading}
                                    />
                                </InitialTransition>
                            ) : (
                                <Home
                                    slideInMenu={slideInMenu}
                                    showMenuIcon={showMenuIcon}
                                    setShowMenuIcon={setShowMenuIcon}
                                    sideMenu={sideMenu}
                                    setSideMenu={setSideMenu}
                                    setHamburgerMenuBlue={setHamburgerMenuBlue}
                                    hamburgerMenuBlue={hamburgerMenuBlue}
                                    setIsLoading={setIsLoading}
                                    isLoading={isLoading}
                                />
                            )
                        }
                    />
                    <Route
                        path="project/:title"
                        element={
                            <InitialTransition key="project">
                                <Works
                                    slideInMenu={slideInMenu}
                                    showMenuIcon={showMenuIcon}
                                    setShowMenuIcon={setShowMenuIcon}
                                    sideMenu={sideMenu}
                                    setSideMenu={setSideMenu}
                                    setHamburgerMenuBlue={setHamburgerMenuBlue}
                                    hamburgerMenuBlue={hamburgerMenuBlue}
                                />
                            </InitialTransition>
                        }
                    />
                    <Route
                        path="all-projects"
                        element={
                            <InitialTransition key="all-projects">
                                <AllWorks
                                    slideInMenu={slideInMenu}
                                    showMenuIcon={showMenuIcon}
                                    setShowMenuIcon={setShowMenuIcon}
                                    sideMenu={sideMenu}
                                    setSideMenu={setSideMenu}
                                    setHamburgerMenuBlue={setHamburgerMenuBlue}
                                    hamburgerMenuBlue={hamburgerMenuBlue}
                                />
                            </InitialTransition>
                        }
                    />
                    <Route
                        path="/about"
                        element={
                            <InitialTransition key="about">
                                <AboutContent
                                    isAnimationDone={isAnimationDone}
                                    slideInMenu={slideInMenu}
                                    showMenuIcon={showMenuIcon}
                                    setShowMenuIcon={setShowMenuIcon}
                                    sideMenu={sideMenu}
                                    setSideMenu={setSideMenu}
                                    setHamburgerMenuBlue={setHamburgerMenuBlue}
                                    hamburgerMenuBlue={hamburgerMenuBlue}
                                />
                            </InitialTransition>
                        }
                    />
                    <Route
                        path="/contact"
                        element={
                            <InitialTransition key="contact">
                                <ContactSection
                                    slideInMenu={slideInMenu}
                                    showMenuIcon={showMenuIcon}
                                    setShowMenuIcon={setShowMenuIcon}
                                    sideMenu={sideMenu}
                                    setSideMenu={setSideMenu}
                                />
                            </InitialTransition>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </AnimatePresence>
    );
}

export default App;
