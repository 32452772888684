import React, { useState, useEffect } from "react";
import "./TechSection.css";
import Marquee from "react-fast-marquee";
import { icons } from "../../Tools";
import { motion } from "framer-motion";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

function TechSection() {
  const categories = Object.keys(icons);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const additionToOthers = ["Testrail", "NightWatch.js"];

  const activeCategory = categories[activeCategoryIndex];

  const handleNextCategory = (e) => {
    e.stopPropagation();
    setActiveCategoryIndex((prevIndex) => (prevIndex + 1) % categories.length);
  };
  const handlePrevCategory = (e) => {
    e.stopPropagation();
    setActiveCategoryIndex(
      (prevIndex) => (prevIndex - 1 + categories.length) % categories.length
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const shouldUseMarquee =
    icons[activeCategory].length > 5 ||
    (windowWidth <= 672 && icons[activeCategory].length >= 3);

  return (
    <div className="expanded_tech_grid">
      <div className="option_buttons">
        {windowWidth <= 509 ? (
          <div className="changed-option_button">
            <ul className="category_btns">
              <IoIosArrowBack onClick={handlePrevCategory} />
              <a>{activeCategory}</a>
              <IoIosArrowForward onClick={handleNextCategory} />
            </ul>
          </div>
        ) : (
          <ul>
            {categories.map((category, index) => (
              <motion.button
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6 }} // Set the duration for the animation
                key={category}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveCategoryIndex(index);
                }}
                className={`category_btns ${
                  activeCategory === category ? "active" : ""
                }`}
              >
                {category}
              </motion.button>
            ))}
          </ul>
        )}
      </div>

      {shouldUseMarquee ? (
        <Marquee speed={60} gradient={false} className="carousel-content" >
          <motion.div
          
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }} // Set the duration for the animation
            className="option_content"
          >
            {icons[activeCategory].map((tool, index) => (
              <motion.div key={index} className="box">
                <i
                  data-name={tool.icon.props.children}
                  data-description={tool.description}
                  className={`status-color ${
                    tool.status === "relevant" ? "relevant" : "notrelevant"
                  }`}
                >
                  {tool.icon}
                </i>
              </motion.div>
            ))}
            {activeCategory === "OTHERS" && (
              <div className="custom-box">
                {" "}
                {additionToOthers.map((item) => (
                  <i className="item_text">{item}</i>
                ))}
              </div>
            )}
          </motion.div>
        </Marquee>
      ) : (
        <motion.div
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          exit={{ x: 300, opacity: 0 }}
          className="option_content"
        >
          {icons[activeCategory].map((tool, index) => (
            <div key={index} className="box">
              <i
                data-name={tool.icon.props.children}
                data-description={tool.description}
                className={`status-color ${
                  tool.status === "relevant" ? "relevant" : "notrelevant"
                }`}
              >
                {tool.icon}
              </i>
            </div>
          ))}
          {activeCategory === "OTHERS" && (
            <>
              {additionToOthers.map((item, index) => (
                <div key={index} className="box">
                  <i className="item_text">{item}</i>
                </div>
              ))}
            </>
          )}
        </motion.div>
      )}
      <motion.div className="knowledge_pointer">
        <span className="relevant-color">Relevant </span>
        <span className="have-experience-color">Have experience</span>
      </motion.div>
    </div>
  );
}

export default TechSection;
