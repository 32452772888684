import React, { useEffect, useRef, useState } from "react";
import { GiDiploma } from "react-icons/gi";
import { FaCode } from "react-icons/fa";
import { CiGlobe } from "react-icons/ci";
import { useInView } from "react-intersection-observer";
import "../UI/TimeUI.css";
import { TbWorld } from "react-icons/tb";

const Box = ({ icon: Icon, title, description, period }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className={`box ${inView ? "animated-box" : ""}`} ref={ref}>
      <Icon className="box-icon" />
      <p>{title}</p>
      <h7>{description}</h7>
      <h4>{period}</h4>
    </div>
  );
};

const TimeUI = () => {
  return (
    <div className="timeline-wrapper">
      <div className="timeline-wrapper-corner">
        <h1>Background</h1>
      </div>

      <div className="box-items">
        <Box
          icon={GiDiploma}
          title="Associate degree"
          description="Successfully completed a 2-year program and earned a degree in programming."
          period="2020 - 2022"
        />
        <Box
          icon={GiDiploma}
          title="Bachelor degree"
          description="In pursuit of obtaining a degree in Software Engineering, I decided to continue my studies."
          period="2022 - pending..."
        />
        <Box
          icon={FaCode}
          title="Full-stack web developer"
          description="Participated in a project that lasted over 6 months at Rezekne Academy of Technologies."
          period="2022 - 2023"
        />
        <Box
          icon={TbWorld}
          title="Website 'Garkalni'"
          description="Creator and developer of the Garkalni website."
          period="2024"
        />
      </div>
    </div>
  );
};

export default TimeUI;
