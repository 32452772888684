import React, { useEffect, useRef, useState } from "react";
import "./AboutContent.css";
import { AnimatePresence, motion, useScroll } from "framer-motion";
import SideMenuSection from "../../components/SideMenu/SideMenu";
import HamburgerMenu from "../../components/SideMenu/HamburgerMenu";
import { PiCertificateLight } from "react-icons/pi";
import { IoIosArrowRoundForward } from "react-icons/io";
import { MdCopyright } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Parallax } from "react-parallax";
import { IoDocumentTextOutline } from "react-icons/io5";
import Footer from "../Footer/Footer";
import { useInView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";

import TimeUI from "../UI/TimeUI";
function AboutContent({
  showMenuIcon,
  setShowMenuIcon,
  sideMenu,
  setSideMenu,
  setHamburgerMenuBlue,
  hamburgerMenuBlue
}) {
  const { ref: myRrefBackgroundef, inView: isVisibleBackground } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: refExperience, inView: isVisibleExperience } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="about-content-wrapper">
        <SideMenuSection
          setHamburgerMenuBlue={setHamburgerMenuBlue}
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
        />
        <HamburgerMenu
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
          showMenuIcon={showMenuIcon}
          setShowMenuIcon={setShowMenuIcon}
          setHamburgerMenuBlue={setHamburgerMenuBlue}
          hamburgerMenuBlue={hamburgerMenuBlue}
        />
        <div
          className="about-content"
        
        >
          <NavLink
            to="/"
            style={{ textDecoration: "none", position: "relative" }}
            className="copyright_txt"
          >
            <MdCopyright /> Code by Armands
          </NavLink>
          <div className="box">
            <div className="column">
            <Fade top distance="10%" duration={800}>

              <div className="first-row_text">
                <div className="about-me-text">
                  <p>
                    <span>Hey 👋</span>
                    <br style={{ marginBottom: "1rem" }} /> I'm Armands Taškāns,
                    a web developer with a love for learning and creating.{" "}
                    <br style={{ marginBottom: ".2rem" }} /> Currently, I'm
                    studying software engineering at{" "}
                    <a href="https://www.rta.lv/" target="_blank">
                      RTA
                    </a>{" "}
                    as a Software engineer , where I'm diving deep into the
                    world of coding and technology.
                    <br style={{ marginBottom: ".2rem" }} /> But what really
                    gets me going is the web! I'm deeply passionate about
                    everything related to web development, from creating
                    beautiful and functional websites to exploring the latest
                    trends and technologies in the field.
                  </p>
                  <a
                    href="/assets/resume.pdf"
                    download="resume.pdf"
                    className="download-link"
                  >
                    Curriculum <IoDocumentTextOutline />
                  </a>
                </div>
                <div className="about-me-photo">
                  <div
                    className="photo"
                    style={{
                      transform: `translateY(${scrollY * 0.1}px)`,
                      transition: "transform 0.3s ease-out",
                    }}
                  ></div>
                </div>
              </div>
              </Fade>

              <TimeUI />

              <div
                ref={myRrefBackgroundef}
                className={`second-row-certificates ${
                  isVisibleBackground ? "animated-box" : ""
                }`}
              >
                <div className="second-row_text">
                  <h1>
                    Certificates <span className="line"></span>
                  </h1>
                </div>
                <div className="certificates">
                  <ul>
                    <li>
                      Construction of computer systems{" "}
                      <PiCertificateLight className="certificate-icon" />
                      <mark>Cisco</mark>
                      <span>2020</span>
                    </li>
                    <li>
                      Introduction to Networks{" "}
                      <PiCertificateLight className="certificate-icon" />
                      <mark>Cisco</mark>
                      <span>2020</span>
                    </li>
                    <li>
                      Web Automation with Nightwatch.js{" "}
                      <PiCertificateLight className="certificate-icon" />
                      <mark>TestDevLab</mark>
                      <span>2024</span>
                    </li>
                    <li>
                      React - The Complete Guide 2023 (incl. React Router &
                      Redux )
                      <PiCertificateLight className="certificate-icon" />
                      <mark>Udemy</mark>
                      <span>2022</span>
                    </li>
                    <li>
                      The Complete JavaScript Course 2022: From Zero to Expert!{" "}
                      <PiCertificateLight className="certificate-icon" />
                      <mark>Udemy</mark>
                      <span>2023</span>
                    </li>
                    <li>
                      The Ultimate React Course 2024: React, Redux & More
                      <PiCertificateLight className="certificate-icon" />
                      <mark>Udemy</mark>
                      <span>2023</span>
                    </li>
                    <li>
                      NodeJS - The Complete Guide (MVC, REST APIs, GraphQL,
                      Deno)
                      <PiCertificateLight className="certificate-icon" />
                      <mark>Udemy</mark>
                      <span>2024</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                ref={refExperience}
                className={`third-row ${
                  isVisibleExperience ? "animated-box" : ""
                }`}
              >
                <div className="third-row_text">
                  <h1>
                    Previous experience <span className="line"></span>{" "}
                  </h1>
                </div>
                <div className="third-row_content rta">
                  <div className="shaped-box"></div>
                  <IoIosArrowRoundForward className="arrow-right rta" />
                  <div className="third-row_left-content">
                    <h4>
                      Name |{" "}
                      <a href="https://www.rta.lv/" target="_blank">
                        RTA
                      </a>
                    </h4>
                    <h7>
                      Over a 6-month project duration, my role involved
                      assisting students in writing and developing their senior
                      projects, with an average workload of about 4 hours per
                      day.
                    </h7>
                  </div>

                  <div className="third-row_right-content">
                    <h3>
                      Successfully developed a user-friendly website and API,
                      hosted on Pinterest.io via Docker containers. The website
                      acts as a handy guide, providing insights into every room
                      at RTA (Rezekne Academy of Technologies). Users can easily
                      explore details like room locations, and know whether
                      rooms are in use, available today, or inactive. For active
                      rooms, the system goes the extra mile by showcasing
                      today's operational schedule, including precise start and
                      end times, ensuring users stay informed about room
                      availability at all times.
                    </h3>
                    <NavLink
                      className="work-link"
                      to="/project/Room monitoring"
                      style={{ textDecoration: "none", position: "relative" }}
                    >
                      CHECK{" "}
                      <IoIosArrowRoundForward className="arrow-right-project" />
                    </NavLink>
                  </div>
                  {/* <p>2023</p> */}
                </div>
                <div className="third-row_content garkalni">
                  <div className="shaped-box garkalni-shape"></div>
                  <IoIosArrowRoundForward className="arrow-right garkalni" />
                  <div className="third-row_left-content">
                    <h4>
                      Name | <a href="">GARKALNI</a>
                    </h4>
                    <h7>
                      Is a website for a small business "Garkalni". The retreat
                      'Garkalni' offers active recreation, where you can fully
                      enjoy your free time using our wide range of services,
                      including a treetop adventure course, sauna, jacuzzi, and
                      more, which are regularly expanding and improving.
                    </h7>
                  </div>

                  <div className="third-row_right-content">
                    <h3>
                      React Routes, Leaflet, React Fast Marquee, Embla Carousel,
                      AOS. The website is about to be upgraded by adding
                      language selection option (English, Latvian, Russian).
                    </h3>
                    <NavLink
                      className="work-link"
                      to="/project/Garkalni"
                      style={{ textDecoration: "none", position: "relative" }}
                    >
                      CHECK{" "}
                      <IoIosArrowRoundForward className="arrow-right-project" />
                    </NavLink>
                  </div>
                  {/* <p>2023</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AboutContent;
