import React, { useEffect, useRef, useState } from 'react';

function LazyLoadBackground({ children, backgroundImage, className }) {
    const [isVisible, setIsVisible] = useState(false);
    const divRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.disconnect();
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, [divRef]);

    return (
        <div
            ref={divRef}
            className={className}
            style={{
                background: isVisible ? `url(${backgroundImage}) center/cover no-repeat` : 'none',
            }}
        >
            {children}
        </div>
    );
}

export default LazyLoadBackground;
